import React from 'react';
import './scss/bs-aglavi.scss';
import './App.css';
import Titulo from "./Titulo";
import EnConstruccion from "./EnConstruccion";

function App() {
    return (
        <div>
            <div className="container mt-5"><Titulo/></div>
            <div className='container-fluid mt-5 py-5 fondo-construccion'><EnConstruccion/></div>
        </div>
    );
}

export default App;
