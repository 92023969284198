import React from "react";
import foto from './img/foto.webp'
import Iconos from "./Iconos";
import moment from 'moment'

const Titulo: React.FC = () => {
    return <div className='row'>
        <div className='col-auto'>
            <img src={foto} alt='Alejandro Glavic' className='foto rounded-circle sombra'/>
        </div>
        <div className='col pt-3'>
            <h1 className='mb-0'>Alejandro Glavic</h1>
            <h4 className='text-muted mb-3'>https://alejandroglavic.ar</h4>
            <div className='mb-1'>
                <Iconos icono='persona'/>
                Tengo {moment().diff('1985-12-19', 'years')} años
            </div>
            <div className='mb-1'>
                <Iconos icono='ubicacion'/>
                Vivo en Belgrano, Ciudad de Buenos Aires, Argentina
            </div>
            <div className='mb-1'>
                <Iconos icono='trabajo'/>
                Me dedico al Diseño y Desarrollo de Sistemas Informáticos
            </div>
        </div>
    </div>
}

export default Titulo